const profileStyles = {
    profile_container_size:{
      padding:'0% 5% 0% 5%'
    },
    centerEverything: {
      display: 'flex',
      justifyContent: 'center'
    },
    text_box_shape_right: {
      padding:'0% 10% 0% 0%',
    },
    text_box_shape_left: {
      padding:'0% 0% 0% 10%'
    },
    text_box_border_style:{
      border: '1px solid rgba(0%, 0%, 0%, 0%)', 
      borderBottom: '1px solid #E3E3E3'
    },
    profile_picture_box: {
        background: "rgba(60, 180, 170, 0.3)",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "20px",
        display: 'flex',
        justifyContent: 'center'
    },
    profile_box_color: {
        background: "rgba(60, 180, 170, 0.3)",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "20px"
    },
    profile_padding_Left: {
        padding: '0% 0% 0% 1%'
      },
    profile_background_color: {
        backgroundColor: "#F5F5F5",
    },
    profile_box_layout : {
        width: '128%',
        height: '97%',
        borderRadius: '20px 20px 20px 20px',
      },
    profile_title_Font: {
        fontSize: "140%", 
        fontWeight: "bold"
    },
    right_side_box_1: {
        fontSize: '80%',
        height: '550px',
        marginLeft: '43%',
        // background: 'linear-gradient(180deg, rgba(252, 200, 22, 0.2) 0%, rgba(252, 200, 22, 0) 100%)',
        borderRadius: '20px 20px 20px 20px',
        backgroundColor: "#FFFFFF",
      },
    right_side_box_2: {
        fontSize: '80%',
        height: '550px',
        marginLeft: '43%',
        // background: 'linear-gradient(180deg, rgba(252, 200, 22, 0.2) 0%, rgba(252, 200, 22, 0) 100%)',
        borderRadius: '20px 20px 20px 20px',
        backgroundColor: "#FFFFFF",
    }
  };
  
  export default profileStyles
import menuStyles from './MenuStyles'

function DepartmentsButton() {
  return (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <button
            style={menuStyles.menu_department_button_center}
            className="btn btn-default"
          >
            <div style={{ fontWeight: 'bold' }}>Departments</div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DepartmentsButton;
